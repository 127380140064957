<template>
  <t-form-card
    :title="`Dados Pessoais`"
    :edit="true"> <!-- setado true para não edição -->
    <template v-slot:content>
      <div class="content">
        <div class="fields">
          <div class="sixteen wide field">
            <div class="field">
              <label for="DonorType" class="label">Tipo do doador</label>
              <input type="text" id="donorType" name="donor[type]" class="ui input disabled" disabled placeholder="Tipo Pessoa" :value="donorType">
            </div>

            <div class="sixteen field">
              <label for="DonorTreatment" class="label">Nome do doador</label>
              <div class="wrap-form-inline">
                <select
                  v-if="donor.documentType == 'brazilian_cpf'"
                  name="donor[treatment]"
                  id="donorTreatment"
                  v-model="donor.genderCode"
                  :disabled="!editForm"
                  :class="['ui fluid dropdown treatment', { 'disabled': !editForm } ]">
                  <option
                    v-for="(item, key) in genderCodes"
                    :key="key"
                    :value="item.key"
                    v-text="item.text" />
                </select>
                <input
                  type="text"
                  name="donor[name]"
                  :class="['ui input', { 'right with-selection': donor.documentType == 'brazilian_cpf', 'disabled': !editForm }]"
                  :readonly="!editForm"
                  placeholder="Nome"
                  v-model="fullName" />
              </div>

            </div>
            <div class="field">
              <label for="DonorCpf" class="label">Documento</label>
              <input type="text" id="donorCpf" name="donor[cpf]" class="ui input disabled" readonly placeholder="CPF" :value="donor.identification">
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="false" v-slot:actions>
      <div class="actions" v-show="editForm">
        <button class="tui tuim ui button" @click.prevent="resetForm">Cancelar</button>
        <button class="tui tuim ui primary button" @click.prevent="save">Salvar</button>
      </div>
    </template>
  </t-form-card>
</template>

<script>
// import { updateDonor } from '@/queries/donors/donor.mutations'
import { mapState } from 'vuex'

export default {
  name: "TCardPersonal",
  components: { TFormCard: () => import('@/components/card/index.vue') },

  data () {
    return {
      fullNameDonor: '',
    }
  },

  computed: {
    ...mapState({
      donor: ({ donors }) => donors.donorDetail,
      isLoading: ({ donors }) => donors.loadingDetail,
      genderCodes: ({ donors }) => donors.genderCodes,
      editForm: ({ donors }) => donors.editForm
    }),

    donorType: ({ donor }) => {
      return donor.documentType === "brazilian_cpf" ? "Pessoa Física" : "Pessoa Júridica"
    },
    fullName: {
      get () {
        return this.fullNameDonor
      },
      set (value) {
        this.fullNameDonor = value
        this.donor.fullName = this.fullNameDonor
        this.donor.firstName = this.firstName
        this.donor.lastName = this.lastName
      }
    },
    firstName: ({ fullName }) => fullName ? fullName.split(" ")[0].trim() : '',
    lastName: ({ fullName }) =>
      fullName ? fullName.substring(fullName.indexOf(" "), fullName.length).trim() : ''
  },
  methods: {
    bindPlugins() {
      setTimeout(() => {
        $('.ui.fluid.dropdown').dropdown()
      }, 1300)
    },
  },
  mounted () {
    this.bindPlugins()

    if (!this.donor.fullName) {
      this.fullName = `${this.donor.firstName} ${this.donor.lastName}`
    } else {
      this.fullName = this.donor.fullName
    }
  }
}
</script>

<style lang="scss">
  .tui.tuim.ui.form .wrap-form-inline .ui.fluid.dropdown.treatment {
    min-width: 70px;
    max-width: 70px;
  }
</style>
